import api from "../utils/api";
import i18next from "i18next";
import i18n from "i18next";

export const regionOptions = [
  {label: i18next.t("Kazakhstan"), value: 16},
  {label: i18next.t("otherCountry"), value: 198},
];

// const lang = getCookie(authConstants.language);
const lang = i18n.language || "en";

export const getRegions = () => {
  return api(`/country/${lang}/bonus-commission`, {method: "GET"})
    .then(res => {
      return res
    })
}

export const countryFormatter = (countyId) => {
  switch (+countyId) {
    case 198:
      return i18next.t("otherCountry");
    case 16:
      return i18next.t("Kazakhstan");
    default:

      return ""
  }
}