import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import image from "assets/images/preloader.svg";
import {useSelector} from "react-redux";
import {getAuth} from "../redux/reducers/auth";
import {Roles} from "../common/RoleFormatter";

function PublicRoute({children, isAuth, load, ...rest}) {
  const {right: userRight} = useSelector(getAuth);
  console.log("userRight", userRight)
  if (isAuth && userRight === Roles.ROLE_SALE_MANAGER) {
    return <Redirect to="/screening"/>
  }
  if (isAuth) {
    // return <Redirect to="/clients"/>
    return <Redirect to="/"/>
  }
  if (load) {
    return <div className={`loadingElement`}>
      <img src={image} className={"loadingImage"} alt={"Идёт загрузка..."}/>
    </div>
  }
  return (
    <Route
      {...rest}
      render={
        ({location}) => {
          return (
            <>
              {location.pathname === "/" ?
                <Redirect
                  to={{
                    pathname: `/login`,
                    state: {from: location}
                  }}
                /> : children}
            </>
          )
        }
      }
    />
  );
}

export default PublicRoute;