import React, {useEffect} from 'react';
import moment from "moment";
import packageJson from "../../package.json";


const ReloadPageAfterDeployment = () => {

  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
    return momLatestDateTime.isAfter(momCurrentDateTime);
  };

  const reloadIfNewVersion = () => {
    fetch(`${process.env.BASE_URL}meta.json`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          window.location.reload();
        }
      });
  }

  useEffect(() => {
      window.addEventListener("click", reloadIfNewVersion);
      return () => window.removeEventListener("click", reloadIfNewVersion);
    },
    [])
  return (<> </>);
};

export default ReloadPageAfterDeployment;