import React from 'react';
import moment from "moment";

export const FormatterFullDataForBack = (date)=>{
  return moment(date).format("MM.DD.YYYY HH:mm:ss");
}

export const dataToday = moment().format("DD.MM.YYYY");
export const lastMonth = [
  moment().subtract(1, 'month').startOf('month').format("DD.MM.YYYY"),
  moment().subtract(1, 'month').endOf('month').format("DD.MM.YYYY"),
];

export const last7Days = [
  moment().subtract(7, 'days').format("DD.MM.YYYY"),
  moment().format("DD.MM.YYYY"),
];
export const last30Days = [
  moment().subtract(30, 'days').format("DD.MM.YYYY"),
  moment().format("DD.MM.YYYY"),
];

export const currentMonth = [
  moment().subtract(0, 'month').startOf('month').format("DD.MM.YYYY"),
  moment().format("DD.MM.YYYY"),
];

export const YYYYMMDDFormat = "YYYY-MM-DD";
export const DDMMYYYYFormat = "DD.MM.YYYY";
export const YYYYMMDDFullFormat="YYYY-MM-DDTHH:mm:ss";
export const DDMMYYYYFullFormat = "DD.MM.YYYY | HH:mm:ss";
export const HHmmssFormat = "HH:mm:ss";

  //"2023-10-06T15:41:04"
export const FormatterDateFromTo = (date=moment(), fromFormat, toFormat)=>{
  return moment(date, fromFormat).format(toFormat);
}

const FormatterDateTime = (date) => {
  let dataFormat = moment(date).format("DD.MM.YYYY");
  let timeFormat = moment(date).format("HH:mm");

  return <div className="d-flex align-items-center">
    <div>{dataFormat}</div>
    <div className="title px-2"> |</div>
    <div className="title">{timeFormat}</div>
  </div>
}

export default FormatterDateTime