import * as Yup from "yup";
import {isValidPhoneNumber} from "react-phone-number-input";
import {validationSum} from "../utils/sumValidation";
import i18next from "i18next";


export const validationSchemaForGrid = (t) => Yup.array()
  .of(
    Yup.object().shape({
      budgetFrom: Yup.string()
        .test("testBudgetFrom", t("validation.enterNumber"), (val) => {
          const regex = /^-?\d*(\.|,)?\d*$/;
          let valueDot;
          if (val && regex.test(val.toString())) {
            valueDot = val.replace(",", ".");
          }
          if (val === undefined) {
            return true
          }
          return !isNaN(valueDot)
        })
        // .typeError(i18next.t("validation.enterNumber"))
        .required(t("validation.required")),
      amount: Yup.string()
        .test("testAmount", t("validation.enterNumber"), (val) => {
          const regex = /^-?\d*(\.|,)?\d*$/;
          let valueDot;
          if (val && regex.test(val.toString())) {
            valueDot = val.replace(",", ".");
          }
          if (val === undefined) {
            return true
          }
          return !isNaN(valueDot)
        })
        .required(t("validation.required")),
      currency: Yup.object().required(t("validation.required")), // these constraints take precedence
    })
  )
  .required(t("validation.allFieldsRequired"));


export const validationSchemaAddGeneralCommission = (t) => {
  return (
    Yup.object().shape({
      commission: Yup.number()
        .positive(t("validation.positiveNumber"))
        .typeError(t("validation.enterNumber"))
        .required(t("validation.required"))
        .max(100, t("validation.notMore", {number: 100}))
        .test("testCommission", t("validation.twoSymbolAfterComma"), function (val) {
          return val && validationSum(val)
        }),
      serviceId: Yup.object()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
      countryId: Yup.object()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
    }))
};

export const validationSchemaAddIndividualCommission = (t) => Yup.object().shape({
  commission: Yup.number()
    .positive(t("validation.positiveNumber"))
    .typeError(t("validation.enterNumber"))
    .required(t("validation.required"))
    .max(100, t("validation.notMore", {number: 100}))
    .test("testCommission", t("validation.twoSymbolAfterComma"), function (val) {
      return val && validationSum(val)
    }),
  cabinetId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
  serviceId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
});


export const validationSchemaAddGeneralBonuses = (t) => Yup.object().shape({
  commissions: validationSchemaForGrid(t),
  serviceId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
  countryId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
});

export const validationSchemaAddIndividualBonuses = (t) => Yup.object().shape({
  commissions: validationSchemaForGrid(t),
  cabinetId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
  serviceId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
});

export const emailValidation = Yup.string()
  .max(250, i18next.t("validation.notMore", {number: 250}))
  .email(i18next.t("validation.incorrectEmailt"));

export const passwordValidation = (t) => Yup.string()
  .min(8, t("validation.noLess", {number: "8"}))
  .matches(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/, t("validation.validPassword"));

export const phoneValid = Yup.string()
  .test("testPhone", i18next.t("validation.incorrectPhone"), function (value) {
      if (value) {
        return isValidPhoneNumber(value);
      } else {
        return true
      }
    }
  ).nullable();

