import i18next from "i18next";

export const filterPaymentsType = Object.freeze({
  ALL: "ALL",
  SET: "SET",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
});

export const apiUrls = Object.freeze({
  clients: "/clients/get-all-clients",
  payment_info: "/payment_info/users",
  users: "/users/all",
  generalCommission: "/commissions/get-all-general",
  individualCommissions: "/commissions/get-all-individual",
  createCommission: "/commissions/update",
  deleteCommission: "/commissions/delete",
  generalBonuses: "/bonuses/get-all-general",
  individualBonuses: "/bonuses/get-all-individual",
  createBonuses: "/bonuses/update",
  deleteBonuses: "/bonuses/delete",
  addAccountsYandex: "/attach/direct-accounts",
  addAccountsGoogle: "/attach/adwords-accounts",
  requestFB: "/facebook/registration/get-accounts"
});

export const requestFBStatus = Object.freeze({
  new: "NEW",
  forRevision: "FOR_REVISION",
  inProcessing: "IN_PROCESSING",
  rejected: "REJECTED",
  close: "CLOSE",
  CHANGE_PAYER: "CHANGE_PAYER"
})